<template>
  <CustomDrawer
    ref="CustomDrawer"
    :title="title"
    :show-footer="formStatus !== 'detail'"
    @openedCallback="openedCallback"
    @handleConfirm="handleConfirm"
  >
    <CustomForm
      ref="CustomForm"
      :form-model="formModel"
      :form-items="formItem"
      :form-rules="formRules"
    >
      <UploadImg slot="photoId" slot-scope="{item}" :upload-lists="photoUploadLists" :pic-disable="formStatus === 'detail'" @uploadChange="item.uploadChange" />
      <RegionForm slot="tenant" ref="RegionForm" v-model="formModel.tenant" :disabled="formStatus === 'detail'" :clearable="true" />
    </CustomForm>
  </CustomDrawer>
</template>

<script>
import CustomDrawer from '@/components/CustomDrawer/index'
import CustomForm from '@/components/CustomForm/index'
import { mapActions } from 'vuex'
import RegionForm from '@/components/RegionForm'
import UploadImg from '@/components/UploadImg'
export default {
  name: 'Form',
  components: { UploadImg, RegionForm, CustomDrawer, CustomForm },
  data() {
    return {
      title: '',
      formId: '',
      formStatus: '',
      photoUploadLists: [],
      dict: {
        category: [
          {
            dictDesc: '党群红盟新闻',
            dictPidVal: 'partyNewsType'
          },
          {
            dictDesc: '志愿者新闻',
            dictPidVal: 'volunteerNewsType'
          },
          {
            dictDesc: '新闻公告',
            dictPidVal: 'newsType'
          }
        ],
        partyNewsType: [],
        volunteerNewsType: [],
        newsType: []
      },
      formModel: {
        category: '',
        subCategory: '',
        newTile: '',
        photoId: '',
        newDesc: '',
        tenant: [],
        shows: 0
      }
    }
  },
  computed: {
    formItem() {
      return {
        'category': {
          'elColSpan': 12,
          'component': 'CustomFormSelect',
          'label': '分类',
          'dataField': {
            'label': 'dictDesc',
            'value': 'dictPidVal'
          },
          'data': this.dict.category,
          'disabled': this.formStatus === 'detail',
          'change': () => {
            this.formModel.subCategory = ''
          }
        },
        'subCategory': {
          'elColSpan': 12,
          'component': 'CustomFormSelect',
          'label': '子分类',
          'dataField': {
            'label': 'dictDesc',
            'value': 'dictPidVal'
          },
          'data': this.formModel.category ? this.dict[this.formModel.category] : [],
          'hide': this.formModel.category || false,
          'disabled': this.formStatus === 'detail'
        },
        'newTile': {
          'elColSpan': 24,
          'component': 'CustomFormText',
          'label': '标题',
          'maxlength': 255,
          'disabled': this.formStatus === 'detail'
        },
        'photoId': {
          'elColSpan': 24,
          'label': '图片',
          'slot': 'photoId',
          'uploadChange': data => {
            if (data.length) {
              this.$set(this.formModel, 'photoId', data[0])
            } else {
              this.$set(this.formModel, 'photoId', '')
            }
          }
        },
        'newDesc': {
          'elColSpan': 24,
          'component': 'CustomFormTinymce',
          'label': '内容',
          'disabled': this.formStatus === 'detail'
        },
        'tenant': {
          'elColSpan': 24,
          'label': '服务区域',
          'slot': 'tenant'
        },
        'shows': {
          'elColSpan': 24,
          'component': 'CustomFormRadio',
          'label': '状态',
          'data': ['隐藏', '显示'],
          'clearable': false,
          'disabled': this.formStatus === 'detail'
        }
      }
    },
    formRules() {
      return {
        category: [
          { required: true, message: '分类不能为空', trigger: ['blur', 'change'] }
        ],
        subCategory: [
          { required: true, message: '子分类不能为空', trigger: ['blur', 'change'] }
        ],
        newTile: [
          { required: true, message: '标题不能为空', trigger: ['blur', 'change'] }
        ],
        photoId: [
          { required: true, message: '图片不能为空', trigger: ['blur', 'change'] }
        ],
        newDesc: [
          { required: true, message: '详情不能为空', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  methods: {
    ...mapActions(['getSysDictServe', 'newsPublicDetail', 'newsAdd', 'newsUpdate']),
    getSysDictList(typeCode) {
      this.getSysDictServe({ typeCode }).then(res => {
        if (res.code !== 200) {
          this.$message.error(res.msg)
          return
        }
        this.dict[typeCode] = res.data
      })
    },
    handleAdd() {
      this.init('add')
    },
    handleEdit(id) {
      this.init('edit', id)
    },
    handleDetail(id) {
      this.init('detail', id)
    },
    init(formStatus, id = '') {
      Object.assign(this, this.$options.data.call(this))
      this.dict.category.forEach(res => {
        this.getSysDictList(res.dictPidVal)
      })
      this.$refs.CustomDrawer.handleOpen()
      this.formId = id
      this.formStatus = formStatus
      switch (formStatus) {
        case 'add':
          this.title = '添加'
          break
        case 'edit':
          this.title = '编辑'
          break
        case 'detail':
          this.title = '查看'
          break
        default:
          this.title = '未知'
          break
      }
    },
    openedCallback() {
      this.initData().then(() => {
        this.$nextTick(() => {
          this.$refs.RegionForm.initRegion()
        })
        this.$refs.CustomForm.$refs.form.clearValidate()
        this.$refs.CustomDrawer.closeLoading()
      }).catch(() => {
        this.$refs.CustomDrawer.handleClose()
      })
    },
    initData() {
      return new Promise((resolve, reject) => {
        if (this.formStatus !== 'add') {
          this.newsPublicDetail({ id: this.formId }).then(res => {
            if (res.code !== 200) {
              this.$message.error(res.msg)
              reject()
              return
            }

            this.photoUploadLists = [
              {
                'id': res.data.photoId,
                'url': res.data.photo
              }
            ]

            this.formModel.id = this.formId
            this.formModel.category = res.data.category
            this.formModel.subCategory = res.data.subCategory
            this.formModel.newTile = res.data.newTile
            this.formModel.photoId = res.data.photoId
            this.formModel.newDesc = res.data.newDesc
            this.formModel.tenant = res.data.tenant.split(',').slice(2)
            this.formModel.shows = res.data.shows
            resolve()
          }).catch(() => {
            reject()
          })
        } else {
          resolve()
        }
      })
    },
    handleConfirm() {
      this.$refs.CustomDrawer.openLoading()
      this.$refs.CustomForm.$refs.form.validate(res => {
        if (res) {
          const params = { ...this.formModel }
          let tenantText = '1,101'
          tenantText += params.tenant.length ? `,${params.tenant.join(',')}` : ''
          params.tenant = tenantText

          switch (this.formStatus) {
            case 'add':
              this.newsAdd(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('添加成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            case 'edit':
              this.newsUpdate(params).then(res => {
                if (res.code !== 200) {
                  this.$refs.CustomDrawer.closeLoading()
                  this.$message.error(res.msg)
                  return
                }
                this.$message.success('修改成功')
                this.$emit('submit')
                this.$refs.CustomDrawer.handleClose()
              }).catch(() => {
                this.$refs.CustomDrawer.closeLoading()
              })
              break
            default:
              this.$refs.CustomDrawer.handleClose()
              break
          }
        } else {
          this.$refs.CustomDrawer.closeLoading()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
